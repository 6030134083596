<template>
<div></div>
</template>

<script>
export default {

    data() {
        return {}
    },
    mounted() {

        // if (localStorage.uud_session) {

        //     if (localStorage.uud_session.split("").splice(-122)[0] == 1) {
        //         this.$router.push({
        //             name: "Panel Principal Master",
        //         });
        //     } else if (localStorage.uud_session.split("").splice(-122)[0] == 2) {
        //         this.$router.push({
        //             name: "Panel de Control",
        //         });
        //     }
        // }
    //  this.$router.push({
    //                 name: "Panel de Control",
    //             });
        if (this.$store.state.user) {
            if (this.$store.state.user.uidType == '4b450506-0380-4a54-b05a-ba623fe749a4') {
                this.$router.push({
                    name: "Panel Principal master",
                });
            } else if (this.$store.state.user.uidType == 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef') {
                this.$router.push({
                    name: "Panel de Control",
                });
            }
            else {
                 this.$router.push({
                    name: "login",
                });
                localStorage.clear();
            }
        }    else {
                 this.$router.push({
                    name: "login",
                });
                localStorage.clear();
            }
    }
}
</script>
